import { Component, AfterViewInit, OnDestroy, ElementRef, ViewChild, Input } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_brazilLow from "@amcharts/amcharts5-geodata/brazilLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { MapChartInputData } from './map.type';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss'
})
export class MapComponent implements AfterViewInit, OnDestroy {
  @ViewChild('chartdiv', { static: true }) chartDiv!: ElementRef;
  @Input() data: MapChartInputData = [];

  private root!: am5.Root;

  ngAfterViewInit(): void {
    this.root = am5.Root.new(this.chartDiv.nativeElement);

    // Set themes
    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);

    // Create chartd
    let chart = this.root.container.children.push(am5map.MapChart.new(this.root, {
      panX: "none",
      panY: "none",
      wheelX: "none",
      wheelY: "none",
      projection: am5map.geoMercator(),
      layout: this.root.horizontalLayout,
    }));

    // Create polygon series
    let polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(this.root, {
      geoJSON: am5geodata_brazilLow,
      valueField: "value",
      calculateAggregates: true
    }));

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}: {value}"
    });

    polygonSeries.set("heatRules", [{
      target: polygonSeries.mapPolygons.template,
      dataField: "value",
      min: am5.color('#9E69FA'),
      max: am5.color('#1E0440'),
      key: "fill",
    }]);


    polygonSeries.mapPolygons.template.adapters.add("fill", function(fill, target) {
      const context = target.dataItem?.dataContext as any;
      if (!context.value) {
        return am5.color('#8a8a8a');
      }
      return fill;
    });

    polygonSeries.data.setAll(this.preProcessData(this.data));
  }

  ngOnDestroy(): void {
    if (this.root) {
      this.root.dispose();
    }
  }

  preProcessData(data: {id: string; value: number}[]) {
    var allStateIds = [
      "BR-AC", "BR-AL", "BR-AP", "BR-AM", "BR-BA", "BR-CE", "BR-DF", "BR-ES",
      "BR-GO", "BR-MA", "BR-MT", "BR-MS", "BR-MG", "BR-PA", "BR-PB", "BR-PR",
      "BR-PE", "BR-PI", "BR-RJ", "BR-RN", "BR-RS", "BR-RO", "BR-RR", "BR-SC",
      "BR-SP", "BR-SE", "BR-TO"
    ];

    return allStateIds.map(id => {
      var dataItem = data.find(item => item.id === id);
      console.log(dataItem);
      return {
        id: id,
        value: dataItem ? dataItem.value : 0 
      };
    });
  }
}
