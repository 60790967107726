import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { LOCALE_ID } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTooltip,
  ApexStroke,
  NgApexchartsModule,
} from 'ng-apexcharts';
import { DashboardService } from '../../../services/dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../../../services/accounts.service';
import { Subscription } from 'rxjs';
import { DateTime } from 'luxon';
import { StoreService } from '../../../services/store.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
};

interface IChartInfo {
  income: string;
  netIncome: string;
  averageTicket: string;
  date: string;
}

@Component({
  selector: 'app-charts',
  standalone: true,
  imports: [BaseChartDirective, NgApexchartsModule],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
  ],
  templateUrl: './charts.component.html',
  styleUrl: './charts.component.scss',
})
export class ChartsComponent implements OnInit, OnDestroy {
  @Input() description!: string;
  @Input() chartData: any;
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions!: ChartOptions;

  /**
   * Selected stores.
   */
  private selectedStoresSubscription: Subscription | undefined;

  /**
   * Stores ids.
   */
  public storesIds: number[] = [];

  constructor(
    private dashboardService: DashboardService,
    private toastr: ToastrService,
    private storeService: StoreService
  ) {}

  ngOnInit(): void {
    this.selectedStoresSubscription =
      this.storeService.selectedStores$.subscribe((selectedStores) => {
        this.storesIds = selectedStores.map(
          (selectedStore) => selectedStore.id
        );

        this.getChartData();
      });
  }

  ngOnDestroy(): void {
    if (this.selectedStoresSubscription) {
      this.selectedStoresSubscription.unsubscribe();
    }
  }

  monthlyRevenue: IChartInfo[] = [];
  monthlyRevenuePrevious: IChartInfo[] = [];

  getChartData(): void {
    const now = DateTime.now().endOf('day').toUTC().toISO();
    const lastMonth = DateTime.now()
      .minus({ month: 1 })
      .startOf('day')
      .toUTC()
      .toISO();
    this.dashboardService
      .chartInvoicing(lastMonth, now, this.storesIds)
      .subscribe((res) => {
        const { error_code } = res;
        if (error_code) {
          const { message } = res;
          this.toastr.error(message);
        } else {
          const { body } = res;
          const { incomes } = body;
          const { selectedPeriod, previousPeriod } = incomes;

          this.chartOptions = {
            series: [
              {
                name: 'Período atual',
                data: selectedPeriod.map((value: IChartInfo) => ({
                  x: value.date,
                  y: value.income,
                })),
                color: '#9e69fa',
              },
              {
                name: 'Período anterior',
                data: previousPeriod.map((value: IChartInfo) => ({
                  x: value.date,
                  y: value.income,
                })),
                color: 'rgba(30, 4, 64, 0.2)',
              },
            ],
            chart: {
              height: 280,
              type: 'area',
              redrawOnWindowResize: true,
              redrawOnParentResize: true,
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: 'smooth',
            },
            xaxis: {
              type: 'category',
              tickAmount: 4,
              labels: {
                formatter(value, timestamp, opts) {
                  if (!value) {
                    return '';
                  }
                  return `${DateTime.fromFormat(value, 'yyyy-MM-dd')
                    .toFormat('dd/MM/yyyy')
                    .toString()}`;
                },
                rotate: 0,
                hideOverlappingLabels: true,
              },
            },
            tooltip: {
              x: {
                show: false,
              },
              y: {
                formatter: function (value: number, opts: any) {
                  const series =
                    opts.seriesIndex === 0 ? selectedPeriod : previousPeriod;
                  const dateCompared = DateTime.fromFormat(
                    series.at(opts.dataPointIndex).date,
                    'yyyy-MM-dd'
                  )
                    .toFormat('dd/MM/yyyy')
                    .toString();
                  const options = {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 3,
                  };
                  return `<div class="chart-tooltip-custom">
                    <p>${dateCompared}:</p>
                    <span>${new Intl.NumberFormat('pt-BR', options).format(
                      Number(value)
                    )} <span/>
                  </div>`;
                },
                title: {
                  formatter(_) {
                    return '';
                  },
                },
              },
              items: {
                display: 'flex',
              },
            },
          };

          this.chart.render();
        }
      });
  }
}
