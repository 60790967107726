<div class="charts">
  <div class="charts__heading">
    <p>{{ description }}</p>
  </div>
  <div class="charts__body">
    <!-- CHART.JS -->
    <!-- <canvas baseChart [data]="barChartData" [options]="barChartOptions" [type]="'line'"> </canvas> -->
  
    <!-- APEXCHARTS -->
    <div id="chart">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [stroke]="chartOptions.stroke"
        [tooltip]="chartOptions.tooltip"
        [dataLabels]="chartOptions.dataLabels"
      ></apx-chart>
    </div>
  </div>
</div>
