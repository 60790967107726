import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PATH_CLAIMS, PATH_CURRENT_STATUS, PATH_INCOMES_CHART, PATH_INCOMES_TODAY, PATH_ORDERS_STATES, PATH_ORDERS_SUMMARY, PATH_ORDER_ITEMS, PATH_REPORTS_OVERVIEW } from '../assets/routes';
import { BaseService } from './base/base.service';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class DashboardService extends BaseService {
    constructor(http: HttpClient) {
        super(http);
    }


    public dailyInvoicing(dateFrom: string, dateTo: string, storesIds?: number[]): Observable<any> {

        return super.get(
            PATH_INCOMES_TODAY,
            {},
            {
                storeId: storesIds,
                dateFrom,
                dateTo
            },
        );
    }

    public chartInvoicing(dateFrom: string, dateTo: string, storesIds?: number[]): Observable<any> {

        return super.get(
            PATH_INCOMES_CHART,
            {},
            {
                storeId: storesIds,
                dateFrom,
                dateTo
            },
        );
    }

    public chartMapOrdersStates(dateFrom: string, dateTo: string, storesIds?: number[]): Observable<any> {
        return super.get(
            PATH_ORDERS_STATES,
            {},
            {
                storeId: storesIds,
                dateFrom,
                dateTo
            }
        );
    }

    public getClaims(storesIds?: number[]): Observable<any> {

        return super.get(
            PATH_CLAIMS,
            {},
            {
                storeId: storesIds,
            }
        );
    }

    public getReportsOverview(dateFrom: string, dateTo: string, storesIds?: number[]): Observable<any> {
        return super.get(
            PATH_REPORTS_OVERVIEW,
            {},
            {
                storeId: storesIds,
                dateFrom,
                dateTo,
            }
        );
    }

    public getOrdersSummary(storesIds?: number[]): Observable<any> {
        const today = DateTime.now();

        return super.get(
            PATH_ORDERS_SUMMARY,
            {},
            {
                dateFrom: today.startOf('day').toUTC().toISO(),
                dateTo: today.endOf('day').toUTC().toISO(),
                storeId: storesIds
            }
        );
    }

    public getCurrentStatus(dateFrom: string, dateTo: string, storesIds?: number[]): Observable<any> {

        return super.get(
            PATH_CURRENT_STATUS,
            {},
            {
                storeId: storesIds,
                dateFrom,
                dateTo
            }
        );
    }

    public listOrderItemsToday(dateFrom: string, dateTo: string, storesIds?: number[]): Observable<any> {
        return super.get(
            PATH_ORDER_ITEMS,
            {},
            {
                storeId: storesIds,
                dateFrom,
                dateTo
            }
        );
    }
}
